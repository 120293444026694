import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import Layout from '../components/layout';
import FooterPreface from '../blocks/footerPreface';
import VisibilitySensor from 'react-visibility-sensor';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';

const ThankYouContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 510px);
  flex-direction: column;
  min-height: 300px;
  @media (max-width: 795px) {
    margin-left: 5%;
    width: 90%;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
  @media (max-width: 795px) {
    font-size: 45px;
    line-height: 30px;
  }
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
  max-width: 700px;
`;

export class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <Layout
          logo={'crimson_black'}
          header_items_color={'ffffff'}
          mobile_logo={'crimson_black'}
          mobile_header_items_color={'ffffff'}
          fixed_background_color={'false'}
          fixed_background_color={'#212121'}
          fixed_menu_items_color={'ffffff'}
          fixed_menu_logo={'crimson_black'}
          fixed_mobile_logo={'crimson_black'}
          menu_background={'true'}
          footer_logo={'crimson_black'}
        >
          <ThankYouContainer>
            <Title>
              {this.props.data.allPrismicThankYouPage.nodes[0].data.title.text}
            </Title>
            <Subtitle>
              {
                this.props.data.allPrismicThankYouPage.nodes[0].data.subtitle
                  .text
              }
            </Subtitle>
            <ButtonNotUnderlined
              color={'#fff'}
              fontSize={'22px'}
              arrowColor={
                this.props.data.allPrismicThankYouPage.nodes[0].data
                  .cta_arrow_color
              }
              link={
                this.props.data.allPrismicThankYouPage.nodes[0].data.cta_link
                  .url
              }
              buttonText={
                this.props.data.allPrismicThankYouPage.nodes[0].data.cta_text
                  .text
              }
              target={
                this.props.data.allPrismicThankYouPage.nodes[0].data.cta_link
                  .target
              }
            />
          </ThankYouContainer>
        </Layout>
      </VisibilitySensor>
    );
  }
}

export default ThankYou;

export const query = graphql`
  query thankYouQuery {
    allPrismicThankYouPage {
      nodes {
        data {
          title {
            text
          }
          subtitle {
            text
          }
          cta_text {
            text
          }
          cta_arrow_color
          cta_link {
            url
            slug
            target
          }
        }
      }
    }
  }
`;
